import {
    Math as cMath,
    HeadingPitchRoll,
    Transforms,
    Cartesian3,
    BoundingSphere,
    Ellipsoid,
    ConstantPositionProperty,
    ArcType,
    Color,
    LabelStyle,
    PolylineDashMaterialProperty,
    CallbackProperty,
    Cartographic,
    HorizontalOrigin,
    VerticalOrigin,
    Cartesian2,
    HeightReference,
    SceneMode,
    DistanceDisplayCondition,
    LabelGraphics,
    NearFarScalar,
    ColorGeometryInstanceAttribute,
    Primitive,
    GeometryInstance,
    SimplePolylineGeometry,
    PolylineColorAppearance,
    LabelCollection,
    PerInstanceColorAppearance,
    EntityCollection,
    PolygonGraphics,
    ProviderViewModel,
    buildModuleUrl,
    BingMapsImageryProvider,
    BingMapsStyle,
    UrlTemplateImageryProvider,
    createDefaultImageryProviderViewModels
} from "cesium";

import {
    ConvertFeetToMeters,
    ConvertISOToDate,
    ConvertMetersToFeet,
    ConvertCoordinatesToPosition,
    ConvertVerticesToPosition,
    ConvertNauticalMilesToMeters,
    GetVerticalSpeedIndicator,
    ConvertVerticesToAveragePosition,
    GetDegreesArrayFromLatLongAndRadius,
    GetColorFromState,
    GetDegreesArrayFromPolygon
} from "../util";
import { createPolygon, createCircle, getOperationPriority } from "../manager/operations/opUtil";
import { getDpzRingModel, updateModel, getCoastedModel } from "./mapModels";
import polylabel from "polylabel";

const SIMULATED_ENTITY_COLOR = "#00ddff";

export function getMessageSeverity(severity) {
    let color;
    if (severity === "WARNING") {
        color = Color.FIREBRICK;
    } else {
        color = Color.GOLD;
    }
    return color;
}

export function setupOrientation(position, heading_deg) {
    const heading = cMath.toRadians(heading_deg - 90); //Subtracting 90 degrees due to initial orientation of model.
    const pitch = 0;
    const roll = 0;
    const hpr = new HeadingPitchRoll(heading, pitch, roll);
    const orientation = Transforms.headingPitchRollQuaternion(position, hpr);

    return orientation;
}

export function getSourceName(sensor_id, sourceData) {
    let sourceName = "Unknown";

    if (sourceData) {
        if (sourceData.get(sensor_id)) sourceName = sourceData.get(sensor_id);
        else if (sensor_id) sourceName = sensor_id;
    } else {
        sourceName = sensor_id;
    }

    return sourceName;
}

export function buildADSBDescription(asd, sourceData) {
    const source_name = getSourceName(asd.source_id, sourceData);
    const latitude = asd.lat_deg ? asd.lat_deg.toFixed(5) : asd.lat_deg;
    const longitude = asd.lon_deg ? asd.lon_deg.toFixed(5) : asd.lon_deg;
    const coasted = asd.coasted ? "COASTED" : asd.eot ? "EOT" : "";
    const color = asd.eot ? "#CB4C4E" : "#E0D268";

    return `<p>Tail Number: ${asd.tail_number}</p>
            <p>Track Angle: ${asd.course_deg}°</p>
            <p>Position: ${latitude}, ${longitude}</p>
            <p>Altitude (HAE): ${asd.alt_hae_ft}ft</p> 
            <p>Ground Speed: ${asd.ground_speed_kn}kn</p>
            <p>Source Type: ${asd.source_type}</p> 
            <p>Classification: ${asd.classification}</p> 
            <p>Source Name: ${source_name}</p>
            <p>Last Update: ${ConvertISOToDate(asd.timestamp)}</p>
            <p style="color:${color}">${coasted}</p>`;
}

export function createPolygonEntity(op, opacity) {
    const polygonEntity = {
        polygon: {
            hierarchy: Cartesian3.fromDegreesArray(op.positions),
            height: op.altitude_lower_hae,
            extrudedHeight: op.altitude_upper_hae,
            material: Color.fromCssColorString(op.color).withAlpha(opacity),
            arcType: ArcType.GEODESIC,
            outlineColor: Color.WHITE,
            outlineWidth: 1,
            outline: true
        },
        name: op.name,
        description: op.description
    };

    return polygonEntity;
}

export function createLAANCVolumeEntity(volume, index) {
    let description =
        "<p>LAANC Submission State: " +
        volume.faa_request_type +
        "</p> \
                       <p>LAANC Volume State: " +
        volume.state +
        "</p> \
                       <p>LAANC Reference Code: " +
        volume.id +
        "</p> \
                       <p>Time Start: " +
        ConvertISOToDate(volume.time_start) +
        "</p> \
                       <p>Time End: " +
        ConvertISOToDate(volume.time_end) +
        "</p>";

    if (volume.advisories) {
        volume.advisories.forEach((advisory) => {
            description += "<p><b>" + advisory.type + "</b> :  " + advisory.msg + "</p>";
        });
    }

    const color =
        volume.faa_request_type === "AUTO_APPROVED"
            ? "#E0D268"
            : volume.faa_request_type === "FURTHER_COORDINATION" || volume.faa_request_type === "BLOCKED"
            ? "#CB4C4E"
            : "#FFFFFF";
    const entity = {
        name: "Volume " + (index + 1),
        description: description
    };

    if (volume.polygon) {
        const positions = ConvertVerticesToPosition(volume.polygon);
        entity.polygon = {
            hierarchy: Cartesian3.fromDegreesArray(positions),
            height: volume.altitude_min_hae_m,
            extrudedHeight: volume.altitude_max_hae_m,
            material: Color.fromCssColorString(color).withAlpha(0.5),
            arcType: ArcType.GEODESIC,
            outlineColor: Color.fromCssColorString(color),
            outlineWidth: 8,
            outline: true
        };
    } else if (volume.circle) {
        entity.position = Cartesian3.fromDegrees(volume.circle.center.lng, volume.circle.center.lat);
        entity.ellipse = {
            semiMinorAxis: volume.circle.radius_m,
            semiMajorAxis: volume.circle.radius_m,
            height: volume.altitude_min_hae_m,
            extrudedHeight: volume.altitude_max_hae_m,
            material: Color.fromCssColorString(color).withAlpha(0.5),
            outlineColor: Color.fromCssColorString(color),
            outlineWidth: 8,
            outline: true
        };
    }
    return entity;
}

export function getPolygonCenter(op) {
    var center = BoundingSphere.fromPoints(Cartesian3.fromDegreesArray(op.positions)).center;
    Ellipsoid.WGS84.scaleToGeodeticSurface(center, center);

    return new ConstantPositionProperty(center);
}

export function createCircleEntity(op, opacity) {
    const circleEntity = {
        id: op.id,
        position: Cartesian3.fromDegrees(op.lng, op.lat),
        ellipse: {
            semiMinorAxis: op.radius,
            semiMajorAxis: op.radius,
            height: op.altitude_lower_hae,
            extrudedHeight: op.altitude_upper_hae,
            material: Color.fromCssColorString(op.color).withAlpha(opacity),
            outline: true,
            outlineColor: Color.WHITE,
            outlineWidth: 1
        },
        name: op.name,
        description: op.description
    };

    return circleEntity;
}

export function createLabelEntity(entity, volume, labelSetting) {
    let position = undefined;

    if (entity.position) {
        position = entity.position;
    } else if (volume.positions) {
        const geo_json = [[]];

        for (let i = 0; i < volume.positions.length; i += 2) {
            geo_json[0].push([volume.positions[i], volume.positions[i + 1]]);
        }

        const p = polylabel(geo_json, 1.0);
        const lon = p[0];
        const lat = p[1];
        const alt = volume.altitude_upper_hae + 100;

        position = Cartesian3.fromDegrees(lon, lat, alt);
    }

    const labelEntity = {
        id: entity.id + "_l",
        position: position,
        label: {
            text: entity.name,
            font: "bold 14pt Roboto",
            outlineWidth: 4,
            style: LabelStyle.FILL_AND_OUTLINE,
            distanceDisplayCondition: new DistanceDisplayCondition(0, 300000),
            disableDepthTestDistance: 1000000000,
            show: labelSetting
        }
    };

    return labelEntity;
}

export function createAirEntity(asd, showLabel, org_id, sourceData, model, fillColor, size) {
    const id = asd.id;
    const label = asd.label;
    const source_id = asd.source_id;
    const source_type = asd.source_type;
    const tail_number = asd.tail_number;
    const longitude = asd.lon_deg;
    const latitude = asd.lat_deg;
    const altitude_hae_m = asd.alt_hae_m;
    const heading = asd.course_deg;
    const emitter_type = asd.adsb === undefined ? 0 : asd.adsb.emitter_cat;
    const simulated = asd.simulated ? true : false;
    const history_locations = asd.history_locations;
    const flight_uuid = asd.flight_uuid;

    const position = Cartesian3.fromDegrees(longitude, latitude, altitude_hae_m);
    const showDpz = typeof asd.show_dpz[org_id] !== "undefined" ? asd.show_dpz[org_id] : asd.show_dpz[0];
    const labelVisible = showLabel === "on" ? true : false;
    const offset = size * 75;

    const default_name = tail_number === undefined ? label : tail_number;
    let callsign = "";
    if (asd.callsign && asd.callsign[org_id] !== undefined) {
        callsign = asd.callsign[org_id];
    } else {
        callsign = default_name;
    }
    let daaConfigUuid = "";
    if (asd.daa_configs && asd.daa_configs[org_id] !== undefined) {
        daaConfigUuid = asd.daa_configs[org_id];
    }
    if (simulated) {
        fillColor = SIMULATED_ENTITY_COLOR;
    }
    let asdLabel = tail_number === undefined ? "" : label + "\n";
    asdLabel += `${asd.alt_hae_ft}ft ${GetVerticalSpeedIndicator(asd.climb_rate_fpm)}\n${asd.ground_speed_kn}kn`;

    const entity = {
        id: id,
        agl: "--",
        course: "--",
        heading_deg: heading,
        show_dpz: showDpz,
        source_id: source_id,
        source_type: source_type,
        emitter_type: emitter_type,
        speed_kn: asd.ground_speed_kn,
        name: callsign,
        description: buildADSBDescription(asd, sourceData),
        altitude: asd.alt_hae_ft,
        amsl_ft: asd.alt_msl_ft,
        agl_ft: asd.alt_agl_ft,
        history_locations: history_locations,
        rotation: asd.rotation,
        simulated: simulated,
        position: position,
        flight_uuid: flight_uuid,
        label: {
            show: labelVisible,
            showBackground: true,
            font: "bold 16px sans-serif",
            verticalOrigin: VerticalOrigin.CENTER,
            horizontalOrigin: HorizontalOrigin.LEFT,
            eyeOffset: new Cartesian3(0, 0, -100),
            pixelOffset: new Cartesian2(offset, 0),
            text: asdLabel,
            outlineWidth: 2,
            style: LabelStyle.FILL_AND_OUTLINE,
            outlineColor: Color.BLACK.withAlpha(0.5),
            backgroundColor: Color.BLACK.withAlpha(0.5),
            scaleByDistance: new NearFarScalar(1.5e2, 1.1, 1.5e7, 0.2)
        },
        inAlertVolume: false,
        inZeroConflict: false,
        daa_config_uuid: daaConfigUuid,
        vertical_speed_fpm: asd.climb_rate_fpm
    };

    updateModel(entity, model, fillColor, size);
    return entity;
}
export function createZeroConflictRing(zca, model, id) {
    const position = Cartesian3.fromDegrees(zca.ownship_track.lon_deg, zca.ownship_track.lat_deg, zca.ownship_track.alt_hae_m);
    const entity = {
        id: id,
        name: "Zero Conflict Reporting",
        source_id: zca.ownship_track.source_id,
        altitude: zca.ownship_track.alt_hae_m,
        severity: zca.severity,
        position: position,
        billboard: model
    };
    return entity;
}

export function updateZeroConflictRing(entity, zca, ownship, size) {
    const message_pos = Cartesian3.fromDegrees(zca.ownship_track.lon_deg, zca.ownship_track.lat_deg, zca.ownship_track.alt_hae_m);
    entity.billboard.color = getMessageSeverity(zca.severity);
    entity.severity = zca.severity;
    entity.show = ownship && !ownship.show ? false : true;

    if (ownship && ownship.position) entity.position = ownship.position;
    else entity.position = message_pos;

    if (entity.billboard && size) {
        entity.billboard.scale = size;
    }
}

export function createZeroConflictLine(zca, id, zeroConflictLinePositions) {
    const ownship_lon = zca.ownship_track.lon_deg;
    const ownship_lat = zca.ownship_track.lat_deg;
    const ownship_alt = zca.ownship_track.alt_hae_m;

    const intruder_lon = zca.intruder_track.lon_deg;
    const intruder_lat = zca.intruder_track.lat_deg;
    const intruder_alt = zca.intruder_track.alt_hae_m;

    zeroConflictLinePositions.set(id, [ownship_lon, ownship_lat, ownship_alt, intruder_lon, intruder_lat, intruder_alt]);

    const entity = {
        id: id,
        name: "Zero Conflict Line",
        source_id: zca.ownship_track.source_id,
        altitude: zca.ownship_track.alt_hae_m,
        polyline: {
            material: getMessageSeverity(zca.severity),
            positions: new CallbackProperty(() => Cartesian3.fromDegreesArrayHeights(zeroConflictLinePositions.get(id)), false),
            width: 3
        }
    };

    return entity;
}

export function updateZeroConflictLine(entity, zca, ownship, intruder, zeroConflictLinePositions, size) {
    let start_lon, start_lat, start_alt, end_lon, end_lat, end_alt;
    entity.show = ownship && !ownship.show ? false : true;

    if (ownship && ownship.position) {
        const cartographic = Cartographic.fromCartesian(ownship.position._value);
        start_lon = (cartographic.longitude / cMath.PI) * 180;
        start_lat = (cartographic.latitude / cMath.PI) * 180;
        start_alt = cartographic.height;
    } else {
        start_lon = zca.ownship_track.lon_deg;
        start_lat = zca.ownship_track.lat_deg;
        start_alt = zca.ownship_track.alt_hae_m;
    }

    if (intruder && intruder.position) {
        const cartographic = Cartographic.fromCartesian(intruder.position._value);
        end_lon = (cartographic.longitude / cMath.PI) * 180;
        end_lat = (cartographic.latitude / cMath.PI) * 180;
        end_alt = cartographic.height;
    } else {
        end_lon = zca.intruder_track.lon_deg;
        end_lat = zca.intruder_track.lat_deg;
        end_alt = zca.intruder_track.alt_hae_m;
    }

    entity.polyline.material = getMessageSeverity(zca.severity);
    zeroConflictLinePositions.set(zca.ownship_track.id + zca.intruder_track.id + "_zc_line", [start_lon, start_lat, start_alt, end_lon, end_lat, end_alt]);

    if (entity.billboard && size) {
        entity.billboard.scale = size;
    }
}

export function createUpdateZeroConflictDesc(zca, ownship, intruder, description) {
    const intruder_lon = zca.intruder_track.lon_deg;
    const intruder_lat = zca.intruder_track.lat_deg;
    const intruder_alt = zca.intruder_track.alt_hae_m;

    const entity_id = zca.ownship_track.id + zca.intruder_track.id + "_zc_desc";
    const entity_text = `Horz: ${ConvertMetersToFeet(zca.horiz_sep_m)}ft\nVert: ${ConvertMetersToFeet(zca.vert_sep_m)}ft\nAlert: ${zca.severity}\nTime: ${
        zca.time_los_sec
    }s`;
    const entity_show = ownship && !ownship.show ? false : true;
    const entity_color = getMessageSeverity(zca.severity);

    const message_position = Cartesian3.fromDegrees(intruder_lon, intruder_lat, intruder_alt);
    const entity_position = intruder ? intruder.position : message_position;

    const entity_label = new LabelGraphics({
        text: entity_text,
        outlineWidth: 2,
        fillColor: Color.WHITE,
        outlineColor: Color.BLACK,
        style: LabelStyle.FILL_AND_OUTLINE,
        verticalOrigin: VerticalOrigin.BOTTOM,
        horizontalOrigin: HorizontalOrigin.CENTER,
        backgroundPadding: new Cartesian2(10, 10),
        scaleByDistance: new NearFarScalar(1.5e2, 1, 1.5e7, 0.1),
        eyeOffset: new Cartesian3(0, 0, -100),
        pixelOffset: new Cartesian2(0, -30),
        font: "bold 16px sans-serif",
        backgroundColor: entity_color,
        showBackground: true
    });
    if (description) {
        description.show = entity_show;
        description.position = entity_position;
        description.label = entity_label;
    }
    return {
        id: entity_id,
        show: entity_show,
        position: entity_position,
        label: entity_label
    };
}

export function wrap_neg180_to_180(num) {
    return ((((num + 180) % 360) + 360) % 360) - 180;
}

export function extrapolatePosition(lat, lon, hdg_deg, distance_m) {
    const M_PER_NM = 1852.0;
    const RAD_PER_DEG = Math.PI / 180.0;
    const DEG_PER_RAD = 180.0 / Math.PI;

    hdg_deg = hdg_deg % 360;
    let distance_deg = distance_m / M_PER_NM / 60.0;

    let b = distance_deg * RAD_PER_DEG;
    let cos_b = Math.cos(b);
    let sin_b = Math.sin(b);
    let a = (90.0 - lat) * RAD_PER_DEG;
    let cos_a = Math.cos(a);
    let sin_a = Math.sin(a);
    let C = hdg_deg * RAD_PER_DEG;
    let cos_C = Math.cos(C);

    let cos_c = cos_a * cos_b + sin_a * sin_b * cos_C;
    let c = Math.acos(Math.min(Math.max(cos_c, -1.0), 1.0));
    let sin_c = Math.sin(c);
    const new_lat = 90.0 - c * DEG_PER_RAD;

    let cos_B = (cos_b - cos_c * cos_a) / (sin_c * sin_a);
    let B = Math.acos(Math.min(Math.max(cos_B, -1.0), 1.0));
    if (C > Math.PI) {
        B = -B;
    }
    const new_lon = wrap_neg180_to_180(lon + B * DEG_PER_RAD);

    return [new_lat, new_lon];
}

export function createPredictionLine(asd, id, lead_time, color, showEntity) {
    const simulated = asd.simulated ? true : false;
    const bearing = asd.course_deg;
    const distance = asd.ground_speed_mps * lead_time;

    const start_lon = asd.lon_deg;
    const start_lat = asd.lat_deg;
    const start_alt = asd.alt_hae_m;

    const [final_lat, final_lon] = extrapolatePosition(start_lat, start_lon, bearing, distance);
    const final_alt = asd.alt_hae_m;

    if (simulated) {
        color = SIMULATED_ENTITY_COLOR;
    }

    const entity = {
        id: id,
        simulated: simulated,
        source_type: asd.source_type,
        source_id: asd.source_id,
        name: "Prediction Line",
        show: showEntity,
        altitude: asd.alt_hae_ft,
        polyline: {
            distanceDisplayCondition: 10000,
            material: Color.fromCssColorString(color),
            positions: Cartesian3.fromDegreesArrayHeights([start_lon, start_lat, start_alt, final_lon, final_lat, final_alt]),
            width: 1
        }
    };

    return entity;
}
export function updatePredictionLine(entity, asd, altitudeRange, sourceToggle, show, lead_time, settings) {
    const bearing = asd.course_deg;
    const distance = asd.ground_speed_mps * lead_time;

    const start_lon = asd.lon_deg;
    const start_lat = asd.lat_deg;
    const start_alt = asd.alt_hae_m;

    const [final_lat, final_lon] = extrapolatePosition(start_lat, start_lon, bearing, distance);
    const final_alt = asd.alt_hae_m;

    entity.show = entityIsVisible(entity, sourceToggle, altitudeRange, settings) ? show : false;
    entity.altitude = asd.alt_hae_ft;
    entity.polyline.positions = Cartesian3.fromDegreesArrayHeights([start_lon, start_lat, start_alt, final_lon, final_lat, final_alt]);
}

export function createHistoryLine(asd, id, color, showEntity) {
    const simulated = asd.simulated ? true : false;
    if (simulated) {
        color = SIMULATED_ENTITY_COLOR;
    }
    const entity = {
        id: id,
        show: showEntity,
        simulated: simulated,
        source_type: asd.source_type,
        source_id: asd.source_id,
        altitude: asd.alt_hae_ft,
        name: "History Line",
        polyline: {
            distanceDisplayCondition: 10000,
            material: new PolylineDashMaterialProperty({
                color: Color.fromCssColorString(color)
            }),
            positions: Cartesian3.fromDegreesArrayHeights(asd.history_locations),
            width: 1
        }
    };

    return entity;
}

export function updateHistoryLine(entity, message, altitudes, sourceToggle, show, settings) {
    const positions = message.history_locations;

    entity.show = entityIsVisible(entity, sourceToggle, altitudes, settings) ? show : false;
    entity.altitude = message.alt_hae_ft;
    entity.polyline.positions = Cartesian3.fromDegreesArrayHeights(positions);
}

export function getDpzVisibility(viewer, asd, user, settings) {
    const ownshipId = asd.id;
    const ownshipEntity = viewer.entities.getById(ownshipId);

    if (ownshipEntity) {
        const ownshipEnabled = ownshipEntity.show === true ? true : false;
        const orgEnabled = asd.show_dpz[user.organization_id] !== undefined ? asd.show_dpz[user.organization_id] : asd.show_dpz[0];
        const userEnabled = settings.well_clear_volume === true ? true : false;

        if (ownshipEnabled === true && orgEnabled === true && userEnabled === true) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function createDpz(viewer, asd, id, dpzPositions, user, settings) {
    const lon = asd.lon_deg;
    const lat = asd.lat_deg;
    const alt = asd.alt_hae_m;

    const simulated = asd.simulated ? true : false;
    const showDpz = getDpzVisibility(viewer, asd, user, settings);

    dpzPositions.set(id, { lon: lon, lat: lat, alt: alt });

    // if (simulated) {
    //     color = SIMULATED_ENTITY_COLOR;
    // }

    const color = "#5fa052"; //Temp setting the color to green to make DPZ entities more visible
    const ringModel = getDpzRingModel(settings.entity_size);

    const entity = {
        id: id,
        show: showDpz,
        simulated: simulated,
        source_type: asd.source_type,
        source_id: asd.source_id,
        position: new CallbackProperty(() => Cartesian3.fromDegrees(dpzPositions.get(id).lon, dpzPositions.get(id).lat, dpzPositions.get(id).alt), false),
        altitude: asd.alt_hae_ft,
        name: "DPZ",
        ellipse: {
            height: alt - 10,
            semiMinorAxis: ConvertFeetToMeters(2000.0),
            semiMajorAxis: ConvertFeetToMeters(2000.0),
            outlineColor: Color.fromCssColorString(color),
            material: Color.TRANSPARENT,
            outline: true
        },
        billboard: ringModel
    };
    return entity;
}

export function updateDpz(viewer, entity, asd, id, dpzPositions, user, settings) {
    const lon = asd.lon_deg;
    const lat = asd.lat_deg;
    const alt = asd.alt_hae_m;

    entity.show = getDpzVisibility(viewer, asd, user, settings);
    entity.altitude = asd.alt_hae_ft;
    entity.ellipse.height = alt;
    entity.billboard.scale = settings.entity_size;
    dpzPositions.set(id, { lon: lon, lat: lat, alt: alt });
}

export function createCoastedEntity(asd, id, coastedPositions, settings, coastedVisibility) {
    const lon = asd.lon_deg;
    const lat = asd.lat_deg;
    const alt = asd.alt_hae_m + 1;

    coastedPositions.set(id, { lon: lon, lat: lat, alt: alt });

    const color = asd.eot ? "#CB4C4E" : "#E0D268";
    const coastedModel = getCoastedModel(settings.entity_size, color);

    const entity = {
        id: id,
        show: coastedVisibility,
        source_type: asd.source_type,
        source_id: asd.source_id,
        position: new CallbackProperty(
            () => Cartesian3.fromDegrees(coastedPositions.get(id).lon, coastedPositions.get(id).lat, coastedPositions.get(id).alt),
            false
        ),
        altitude: asd.alt_hae_ft,
        name: "Coasted",
        billboard: coastedModel
    };
    return entity;
}

export function updateCoastedEntity(entity, asd, id, coastedPositions, settings, coastedVisibility) {
    const lon = asd.lon_deg;
    const lat = asd.lat_deg;
    const alt = asd.alt_hae_m + 1;

    entity.show = coastedVisibility;
    entity.altitude = asd.alt_hae_ft;
    entity.billboard.scale = settings.entity_size * 0.75;
    coastedPositions.set(id, { lon: lon, lat: lat, alt: alt });
}

export function createAlertConflictRing(id, model, alertEntity, spzPositions, showAlerts) {
    const position = alertEntity.position.getValue();
    const cartographic = Cartographic.fromCartesian(position);

    const latitude = cMath.toDegrees(cartographic.latitude);
    const longitude = cMath.toDegrees(cartographic.longitude);
    const altitude = ConvertFeetToMeters(alertEntity.altitude);

    const show = getAlertRingVisibility(alertEntity, showAlerts);
    spzPositions.set(id, { lon: longitude, lat: latitude, alt: altitude });

    const entity = {
        id: id,
        name: "Alert Conflict Ring",
        billboard: model,
        show: show,
        source_type: alertEntity.source_type,
        source_id: alertEntity.source_id,
        altitude: altitude,
        position: new CallbackProperty(() => {
            return Cartesian3.fromDegrees(spzPositions.get(id).lon, spzPositions.get(id).lat, spzPositions.get(id).alt);
        }, false)
    };

    return entity;
}

export function formatOperationToDraw(op, index, volume, stateColorList) {
    let operation;

    if (Object.prototype.hasOwnProperty.call(volume, "polygon")) operation = createPolygon(op, volume, stateColorList);

    if (Object.prototype.hasOwnProperty.call(volume, "circle")) operation = createCircle(op, volume, stateColorList);

    if (index == 0) operation.label = true;

    //Storing op type for toggle
    operation.state = op.state;
    operation.uuid = Object.prototype.hasOwnProperty.call(op, "flight_uuid") ? op.flight_uuid : op.constraint_uuid;
    operation.operation_type = op.type;
    operation.version = op.version;
    operation.created_user_id = op.created_user_id;
    operation.owner = op.owner;

    return operation;
}

export function formatConstraintToDraw(con, index, volume, stateColorList) {
    let constraint;

    if (Object.prototype.hasOwnProperty.call(volume, "polygon")) constraint = createPolygon(con, volume, stateColorList);

    if (Object.prototype.hasOwnProperty.call(volume, "circle")) constraint = createCircle(con, volume, stateColorList);

    if (index == 0) constraint.label = true;

    constraint.constraint = con; //Store the original JSON message

    return constraint;
}

export function formatAlertVolumeToDraw(alert, polygon, opacity) {
    let newEntity;

    //Set additional fields needed for display
    polygon.name = alert.name;
    polygon.color = alert.color_rgb;
    polygon.altitude_upper_hae = 0;
    polygon.altitude_lower_hae = 0;
    polygon.positions = ConvertVerticesToPosition(polygon.vertices);

    if (polygon.type == "polygon") {
        newEntity = createPolygonEntity(polygon, opacity);
    } else if (polygon.type == "circle") {
        newEntity = createCircleEntity(polygon, opacity);
    }
    newEntity.name = alert.name;
    newEntity.description = `<p>Alert Volume</p>\n
        <p>Altitude Min (HAE): ${ConvertMetersToFeet(alert.altitude_min_hae_m)}ft</p>\n
        <p>Altitude Max (HAE): ${ConvertMetersToFeet(alert.altitude_max_hae_m)}ft</p>\n
        <p>Altitude Min (AGL): ${ConvertMetersToFeet(alert.altitude_min_agl_m)}ft</p>\n
        <p>Altitude Max (AGL): ${ConvertMetersToFeet(alert.altitude_max_agl_m)}ft</p>\n
        <p>Time Start: ${ConvertISOToDate(alert.time_start)}</p>\n
        <p>Time End: ${ConvertISOToDate(alert.time_end)}</p>\n
        <p>Date Created: ${ConvertISOToDate(alert.date_created)}</p>
    `;
    return newEntity;
}

export function createVolumeEntity(volume, opacity) {
    let newEntity;
    if (volume.type == "polygon") {
        newEntity = createPolygonEntity(volume, opacity);
    }
    if (volume.type == "circle") {
        newEntity = createCircleEntity(volume, opacity);
    }
    if (newEntity) {
        newEntity.uuid = volume.uuid;
        newEntity.altitude = volume.altitude_upper_hae;
        newEntity.altitude_max_agl_m = volume.altitude_max_agl_m;
        newEntity.startTime = volume.time_start;
        newEntity.endTime = volume.time_end;
        newEntity.state = volume.state;
        newEntity.version = volume.version;
        newEntity.type = volume.operation_type;
        newEntity.created_user_id = volume.created_user_id;
        newEntity.owner = volume.owner;
    }

    return newEntity;
}

export function createAirspaceEntity(airspace, airspaceSettings, standaloneMap) {
    const setting = airspaceSettings.find((setting) => setting.airspace_type == airspace.local_type);
    const positions = ConvertCoordinatesToPosition(airspace.coordinates);

    //Default settings
    let airspace_visible = true;
    let airspace_color = "#000000";

    // only show colors/visibility for Airspaces depending on user settings.
    if (setting) {
        airspace_visible = standaloneMap ? setting.visible_map : true;
        airspace_color = setting.color;
    }

    const newAirspace = {
        name: airspace.name,
        show: airspace_visible,
        airspace_type: airspace.local_type,
        polygon: {
            hierarchy: Cartesian3.fromDegreesArray(positions),
            height: 0,
            material: Color.GRAY.withAlpha(0.1),
            outline: true,
            outlineColor: Color.fromCssColorString(airspace_color)
        },
        description:
            "<p>Airspace</p> \
             <p>Airspace Type:  " +
            airspace.local_type +
            "</p> \
             <p>Date Updated:  " +
            ConvertISOToDate(airspace.date_updated) +
            "</p>"
    };

    return newAirspace;
}

export function createStadiumEntity(stadium) {
    const lat = stadium.latitude;
    const lon = stadium.longitude;
    const name = stadium.name;

    const rad = ConvertNauticalMilesToMeters(3);
    const pos = Cartesian3.fromDegrees(lon, lat);
    const material = Color.LIME.withAlpha(0.1);
    const outline = Color.LIME.withAlpha(1);

    const entity = {
        name: name,
        position: pos,
        ellipse: {
            height: 0,
            semiMinorAxis: rad,
            semiMajorAxis: rad,
            material: material,
            outlineColor: outline,
            outline: true
        },
        description:
            "<p>Stadium</p> \
             <p>Name:  " +
            stadium.name +
            "</p> \
             <p>City:  " +
            stadium.city +
            "ft</p> \
             <p>State:  " +
            stadium.state +
            "</p>",
        label: {
            font: "bold 16px sans-serif",
            text: name,
            distanceDisplayCondition: new DistanceDisplayCondition(0.0e6, 0.0015e7),
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE
        }
    };

    return entity;
}

export function createFacilityMap(facilityMap) {
    const color_string = facilityMap.apt1_laanc ? "#90caf9" : "#FF5050";
    const positions = ConvertCoordinatesToPosition(facilityMap.coordinates);
    const newFacilityMap = {
        name: facilityMap.apt1_name,
        ceiling: facilityMap.ceiling_ft,
        position: getPolygonCenter({ positions: positions }),
        description:
            "<p>Facility Map</p> \
             <p>Name:  " +
            facilityMap.apt1_name +
            "</p> \
             <p>Ceiling:  " +
            facilityMap.ceiling_ft +
            "</p> \
             <p>Region:  " +
            facilityMap.region +
            "</p>",
        polygon: {
            hierarchy: Cartesian3.fromDegreesArray(positions),
            height: 0,
            material: Color.fromCssColorString(color_string).withAlpha(0.1),
            outline: true,
            outlineColor: Color.fromCssColorString(color_string)
        },
        label: {
            font: "bold 16px sans-serif",
            text: facilityMap.ceiling_ft + "ft",
            distanceDisplayCondition: new DistanceDisplayCondition(0.0e6, 0.0015e7),
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE,
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.CENTER
        }
    };
    return newFacilityMap;
}

export function createNationalSecurityNoFlyEntity(nationalSecurityNoFly) {
    const positions = ConvertCoordinatesToPosition(nationalSecurityNoFly.coordinates);
    const newNationalSecurityNoFly = {
        name: nationalSecurityNoFly.base,
        position: getPolygonCenter({ positions: positions }),
        polygon: {
            hierarchy: Cartesian3.fromDegreesArray(positions),
            height: 0,
            material: Color.fromCssColorString("#ff355e").withAlpha(0.3),
            outline: true,
            outlineColor: Color.fromCssColorString("#ff355e")
        },
        description:
            "<p>National Security No Fly</p> \
             <p>Base:  " +
            nationalSecurityNoFly.base +
            "</p> \
             <p>Branch:  " +
            nationalSecurityNoFly.branch +
            "</p> \
             <p>County:  " +
            nationalSecurityNoFly.county +
            "</p> \
             <p>State:  " +
            nationalSecurityNoFly.state +
            "</p> \
             <p>Ceiling:  " +
            nationalSecurityNoFly.ceiling +
            "</p> \
             <p>Floor:  " +
            nationalSecurityNoFly.floor +
            "</p> \
             <p>Reason:  " +
            nationalSecurityNoFly.reason +
            "</p> \
             <p>Poc:  " +
            nationalSecurityNoFly.poc +
            "</p>",
        label: {
            font: "bold 16px sans-serif",
            text: nationalSecurityNoFly.name,
            distanceDisplayCondition: new DistanceDisplayCondition(0.0e6, 0.0015e7),
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE
        }
    };
    return newNationalSecurityNoFly;
}

export function createSpecialAirspaceEntity(specialAirspace) {
    const colorString = specialAirspace.type_code == "P" ? "#f2b463" : specialAirspace.type_code == "R" ? "#f5f77c" : "#ffffff";
    const positions = ConvertCoordinatesToPosition(specialAirspace.coordinates);
    const newSpecialAirspace = {
        name: specialAirspace.name,
        position: getPolygonCenter({ positions: positions }),
        polygon: {
            hierarchy: Cartesian3.fromDegreesArray(positions),
            height: 0,
            material: Color.fromCssColorString(colorString).withAlpha(0.5),
            outline: true,
            outlineColor: Color.fromCssColorString(colorString)
        },
        description:
            "<p>Special Airspace</p> \
             <p>Name:  " +
            specialAirspace.name +
            "</p> \
             <p>City:  " +
            specialAirspace.city +
            "</p> \
             <p>State:  " +
            specialAirspace.state +
            "</p> \
             <p>Count Agent:  " +
            specialAirspace.count_agent +
            "</p> \
             <p>Country:  " +
            specialAirspace.country +
            "</p>",
        label: {
            font: "bold 16px sans-serif",
            text: specialAirspace.name,
            distanceDisplayCondition: new DistanceDisplayCondition(0.0e6, 0.0015e7),
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE
        }
    };
    return newSpecialAirspace;
}

export function createSensorEntity(sensor) {
    const longitude = parseFloat(sensor.lon);
    const latitude = parseFloat(sensor.lat);
    const radius = parseFloat(sensor.radius);

    const newSensor = {
        name: sensor.name,
        position: Cartesian3.fromDegrees(longitude, latitude),
        ellipse: {
            height: 0,
            semiMinorAxis: radius,
            semiMajorAxis: radius,
            outlineColor: Color.fromCssColorString("#000000"),
            material: Color.GRAY.withAlpha(0.1),
            outline: true
        },
        description:
            "<p>Radar Footprint</p> \
             <p>Sensor ID:  " +
            sensor.truth_source +
            "</p> \
             <p>Sensor Type:  " +
            sensor.sensor_type +
            "</p>"
    };

    return newSensor;
}

export function entityIsVisible(entity, sourceToggle, altitudes, settings) {
    if (entity.altitude <= 0) {
        entity.altitude = 1;
    }

    let visible;
    if (entity.altitude > altitudes[0] && entity.altitude < altitudes[1]) {
        visible = sourceToggle;
    } else {
        // telemetry entities should not be affected by altitude filter
        if (entity.source_type === "TELEMETRY") {
            visible = sourceToggle;
        } else {
            visible = false;
        }
    }

    if (!visible) {
        return false;
    }

    const source = settings.source_mapping[entity.source_id];
    return !source || source.visibility;
}

export function createPoint(viewer, worldPosition) {
    const heightReference = viewer.scene.mode == SceneMode.SCENE2D ? HeightReference.NONE : HeightReference.CLAMP_TO_GROUND;

    return viewer.entities.add({
        position: worldPosition,
        point: {
            color: Color.WHITE,
            pixelSize: 5,
            heightReference: heightReference
        }
    });
}

export function pickPosition(viewer, mousePosition) {
    const ray = viewer.scene.camera.getPickRay(mousePosition);
    return viewer.scene.globe.pick(ray, viewer.scene);
}

export function isDuplicatePosition(positions, positionToCheck) {
    for (const p of positions) {
        const x = Math.abs(p.x - positionToCheck.x);
        const y = Math.abs(p.y - positionToCheck.y);
        const z = Math.abs(p.z - positionToCheck.z);
        if (x < 5 && y < 5 && z < 5) {
            return true;
        }
    }
    return false;
}

export function zoomToEntities(viewer, entities) {
    try {
        if (entities !== undefined && entities.length > 0) {
            let maxEntityHeight = Number.MIN_VALUE;
            const positions = entities.map((entity) => {
                if (entity.polygon && entity.polygon.hierarchy) {
                    if (entity.polygon.extrudedHeight > maxEntityHeight) {
                        maxEntityHeight = entity.polygon.extrudedHeight;
                    }
                    return entity.polygon.hierarchy.getValue().positions;
                } else if (entity.ellipse) {
                    if (entity.ellipse.extrudedHeight > maxEntityHeight) {
                        maxEntityHeight = entity.ellipse.extrudedHeight;
                    }
                    const cartographic = Cartographic.fromCartesian(entity.position.getValue());
                    const latitude = cMath.toDegrees(cartographic.latitude);
                    const longitude = cMath.toDegrees(cartographic.longitude);
                    const radius = entity.ellipse.semiMinorAxis.getValue();

                    const degreesArray = GetDegreesArrayFromLatLongAndRadius(latitude, longitude, radius);
                    return Cartesian3.fromDegreesArray(degreesArray);
                }
            });
            const points = positions.flat();
            const boundingSphere = BoundingSphere.fromPoints(points);
            const center = Cartographic.fromCartesian(boundingSphere.center);

            const altitude = boundingSphere.radius * 10 + maxEntityHeight;
            const destination = Cartesian3.fromRadians(center.longitude, center.latitude, altitude);

            viewer.camera.setView({
                destination: destination,
                orientation: {
                    heading: 0.0,
                    pitch: cMath.toRadians(-90.0),
                    roll: 0.0
                }
            });
        }
    } catch (err) {
        console.error(err);
    }
}
// ignoring because we cannot mock viewer
/* istanbul ignore next */
export function createUpdateAirEntities(
    viewer,
    messages,
    asdEntities,
    sourceIDs,
    settings,
    user,
    altitudes,
    dpzs,
    sourceToggle,
    operationsMessage,
    flightCardAlerts,
    watchId,
    updatedASDWatchValues,
    zcMessages,
    zcPositions,
    entityTimes,
    dpzIds,
    sourceData,
    spzPositions,
    coastedPositions
) {
    messages.forEach((asd) => {
        const entityId = asd.id;
        const entityPredLineId = asd.id + "_pl";
        const entityHistLineId = asd.id + "_hl";
        const entityDpzId = asd.id + "_dpz";
        const entitySpzId = asd.id + "_spz";
        const entityZcId = asd.id + "_zc";
        const entityCoastedId = asd.id + "_coasted";

        entityTimes.set(entityId, asd.timestamp);
        entityTimes.set(entityPredLineId, asd.timestamp);
        entityTimes.set(entityHistLineId, asd.timestamp);
        entityTimes.set(entityDpzId, asd.timestamp);
        entityTimes.set(entityZcId, asd.timestamp);

        const entity = viewer.entities.getById(entityId);
        const dpzSetting = asd.show_dpz[user.organization_id] !== undefined ? asd.show_dpz[user.organization_id] : asd.show_dpz[0];

        const entitySettings = settings.source_type_settings.find(({ source_type }) => source_type === asd.source_type);
        const model = entitySettings.icon;
        const color = entitySettings.color;
        const pred = settings.pred_line;
        const hist = settings.hist_line;
        let coastedVisibility = false;

        if (asd.coasted || asd.eot) {
            //console.log("Coasted: " + entityId + " Source: " + asd.source_id);
            coastedVisibility = true;
        }

        sourceIDs.add(asd.source_id);

        if (entity) {
            const callsign = asd.callsign[user.organization_id] ? asd.callsign[user.organization_id] : asd.callsign[0];
            const latitude = asd.lat_deg;
            const longitude = asd.lon_deg;
            const altitude = asd.alt_hae_m;
            const position = Cartesian3.fromDegrees(longitude, latitude, altitude);
            const rotation = asd.rotation;
            const altitude_hae_ft = asd.alt_hae_ft;
            const visibility = entityIsVisible(entity, sourceToggle, altitudes, settings);

            let asdLabel = callsign === undefined ? "" : callsign + "\n";
            asdLabel += `${altitude_hae_ft}ft ${GetVerticalSpeedIndicator(asd.climb_rate_fpm)}\n${asd.ground_speed_kn}kn`;

            const pred_line = viewer.entities.getById(entityPredLineId);
            if (pred_line) {
                updatePredictionLine(pred_line, asd, altitudes, sourceToggle, pred, settings.pred_line_lead_time, settings);
            }
            const hist_line = viewer.entities.getById(entityHistLineId);
            if (hist_line && asd.history_locations) {
                updateHistoryLine(hist_line, asd, altitudes, sourceToggle, hist, settings);
            }
            const dpz_entity = viewer.entities.getById(entityDpzId);
            if (dpz_entity) {
                updateDpz(viewer, dpz_entity, asd, entityDpzId, dpzs, user, settings);
            }
            const coasted_entity = viewer.entities.getById(entityCoastedId);
            if (coasted_entity) {
                updateCoastedEntity(coasted_entity, asd, entityCoastedId, coastedPositions, settings, coastedVisibility);
            }
            // need to listen for tagging updates from other users
            entity.name = callsign == undefined ? asd.label : callsign;
            entity.flight_uuid = asd.flight_uuid;

            if (asd.show_dpz && asd.show_dpz[user.organization_id] !== undefined) {
                entity.show_dpz = asd.show_dpz[user.organization_id];
            } else {
                entity.show_dpz = asd.show_dpz[0];
            }
            if (asd.daa_configs && asd.daa_configs[user.organization_id] !== undefined) {
                entity.daa_config_uuid = asd.daa_configs[user.organization_id];
            } else {
                entity.daa_config_uuid = asd.daa_configs[0];
            }

            entity.rotation = rotation;
            entity.history_locations = asd.history_locations;
            entity.description = buildADSBDescription(asd, sourceData);
            entity.altitude = altitude_hae_ft;
            entity.position = position;
            entity.show = visibility;

            const heading_deg = Math.round(asd.course_deg);
            const speed_kn = asd.ground_speed_kn;
            const agl_ft = asd.alt_agl_ft;
            const amsl_ft = asd.alt_msl_ft;

            entity.heading_deg = isNaN(heading_deg) ? "--" : heading_deg;
            entity.speed_kn = isNaN(speed_kn) ? "--" : speed_kn;
            entity.agl_ft = isNaN(agl_ft) ? "--" : agl_ft;
            entity.amsl_ft = isNaN(amsl_ft) ? "--" : amsl_ft;
            entity.vertical_speed_fpm = asd.climb_rate_fpm;

            const associatedOperation = operationsMessage.find((op) => op.asd_uuid === entityId);
            const inAlertVolume = viewer.entities.getById(entitySpzId);
            const inZeroConfliction = viewer.entities.getById(entityZcId);

            if (entity.billboard) {
                entity.billboard.rotation = cMath.toRadians(rotation || 0);
            }
            if (entity.label && entity.label.show) {
                entity.label.text = asdLabel;
            }
            if (entity.label && entity.label.show && !inAlertVolume && settings.label_visible === "alerts") {
                entity.label.show = false;
            }
            if (inAlertVolume) {
                spzPositions.set(entitySpzId, { lon: longitude, lat: latitude, alt: altitude });
            }

            if (associatedOperation) {
                const alertStatusRef = flightCardAlerts.get(associatedOperation.flight_uuid);
                const daaAlertElement = alertStatusRef.current.children[0];
                const alertVolumeElement = alertStatusRef.current.children[1];

                if (inZeroConfliction !== undefined) {
                    const zeroConflictMessage = zcMessages.get(entityId);
                    if (zeroConflictMessage !== undefined) {
                        const color = zeroConflictMessage.severity === "WARNING" ? "red" : "yellow";
                        const comma = inAlertVolume !== undefined ? ", " : "";
                        daaAlertElement.innerHTML = `DAA Alert${comma}`;
                        daaAlertElement.style.color = color;
                    }
                } else {
                    if (inAlertVolume !== undefined) {
                        daaAlertElement.innerHTML = "";
                    } else {
                        daaAlertElement.innerHTML = "--";
                    }
                    daaAlertElement.style.color = "#6b778c";
                }
                if (inAlertVolume !== undefined) {
                    alertVolumeElement.innerHTML = "In Alert Volume";
                    alertVolumeElement.style.color = "red";
                } else {
                    alertVolumeElement.innerHTML = "";
                    alertVolumeElement.style.color = "#6b778c";
                }
            }
            if (watchId === entity.id) {
                updatedASDWatchValues(inAlertVolume, inZeroConfliction, entity);
            }
            zcMessages.forEach((value, key) => {
                if (!key.includes(asd.id)) return;

                const intruder_entity = viewer.entities.getById(value.intruder_track.id);
                const ownship_entity = viewer.entities.getById(value.ownship_track.id);
                const ring_entity = viewer.entities.getById(value.ownship_track.id + "_zc");
                const line_entity = viewer.entities.getById(value.ownship_track.id + value.intruder_track.id + "_zc_line");
                const desc_entity = viewer.entities.getById(value.ownship_track.id + value.intruder_track.id + "_zc_desc");

                if (ring_entity) {
                    updateZeroConflictRing(ring_entity, value, ownship_entity);
                }
                if (desc_entity) {
                    createUpdateZeroConflictDesc(value, ownship_entity, intruder_entity, desc_entity);
                }
                if (line_entity) {
                    updateZeroConflictLine(line_entity, value, ownship_entity, intruder_entity, zcPositions);
                }
            });
        } else {
            const newEntity = viewer.entities.add(
                createAirEntity(asd, settings.label_visible, user.organization_id, sourceData, model, color, settings.entity_size)
            );
            const visibility = entityIsVisible(newEntity, sourceToggle, altitudes, settings);
            newEntity.show = visibility;
            asdEntities.set(newEntity.id, newEntity);

            const newPredLine = viewer.entities.add(createPredictionLine(asd, entityPredLineId, settings.pred_line_lead_time, color, pred));
            newPredLine.show = visibility === true && pred === true ? true : false;
            asdEntities.set(newPredLine.id, newPredLine);

            const newHistLine = viewer.entities.add(createHistoryLine(asd, entityHistLineId, color, hist));
            newHistLine.show = visibility === true && hist === true ? true : false;
            asdEntities.set(newHistLine.id, newHistLine);

            const newDpz = viewer.entities.add(createDpz(viewer, asd, entityDpzId, dpzs, user, settings));
            newDpz.show = visibility === true && settings.well_clear_volume === true && dpzSetting === true ? true : false;
            asdEntities.set(newDpz.id, newDpz);

            const orgSetting = asd.show_dpz[user.organization_id] !== undefined ? asd.show_dpz[user.organization_id] : asd.show_dpz[0];
            dpzIds.set(entityDpzId, orgSetting);

            const newCoastedEntity = viewer.entities.add(createCoastedEntity(asd, entityCoastedId, coastedPositions, settings, coastedVisibility));
            newCoastedEntity.show = visibility === true && coastedVisibility;
            asdEntities.set(newCoastedEntity.id, newCoastedEntity);
        }
    });
}

export function handleToggleVisibilityOfZeroConflictEntities(entities, value, altitudes) {
    entities.forEach((entity) => {
        if (entity.altitude >= altitudes[0] && entity.altitude <= altitudes[1] && value) {
            entity.show = value;
        } else entity.show = false;
    });
}

export function handleToggleVisibilityOfZeroConflictEntitiesBySource(entities, value, source, altitudes) {
    entities.forEach((entity) => {
        if (entity.source_id === source && entity.altitude >= altitudes[0] && entity.altitude <= altitudes[1] && value) {
            entity.show = value;
        } else entity.show = false;
    });
}

export function handleToggleVisibilityOfAlertRingEntities(entities, show_alerts) {
    entities.forEach((entity) => {
        if (entity.id.includes("_spz")) {
            const asd_entity_id = entity.id.split("_")[0];
            const asd_entity = entities.get(asd_entity_id);
            const visibility = show_alerts && asd_entity && asd_entity.show;
            entity.show = visibility;
        }
    });
}

export function handleUpdateAirEntitiesWithNewSettings(entities, settings, sourceToggle, dpzs, altitudes) {
    entities.forEach((entity) => {
        if (entity.id.includes("_zc")) {
            return;
        }

        const isVisible = entityIsVisible(entity, sourceToggle, altitudes, settings);
        const entitySettings = settings.source_type_settings.find(({ source_type }) => source_type === entity.source_type);
        const color = entity.simulated ? SIMULATED_ENTITY_COLOR : entitySettings.color;
        const pred = settings.pred_line;
        const hist = settings.hist_line;
        const wcv = settings.well_clear_volume;

        const updated = updateEntitiesWithSettings(entity, isVisible, pred, sourceToggle, color, hist, wcv, dpzs, settings);
        if (updated) {
            return;
        }

        updateModel(entity, entitySettings.icon, color, settings.entity_size);
        if (entity.label) {
            entity.label.show = settings.label_visible === "on" || (entity.id.includes("_spz") && settings.label_visible === "alerts");
        }
    });
}

function updateEntitiesWithSettings(entity, isVisible, pred, sourceToggle, color, hist, wcv, dpzs, settings) {
    if (entity.id.includes("_pl")) {
        entity.show = isVisible && pred && sourceToggle;
        entity.polyline.material = Color.fromCssColorString(color);
    } else if (entity.id.includes("_hl")) {
        entity.show = isVisible && hist && sourceToggle;
        entity.polyline.material = new PolylineDashMaterialProperty({
            color: Color.fromCssColorString(color)
        });
    } else if (entity.id.includes("_dpz")) {
        entity.show = isVisible && wcv && dpzs.get(entity.id) ? true : false;
        // entity.ellipse.outlineColor = Color.fromCssColorString(color);
    } else if (entity.id.includes("_spz")) {
        entity.billboard.scale = settings.entity_size;
    } else {
        entity.show = isVisible;
        return false;
    }
    return true;
}

export function handleUpdateVolumeEntitiesWithNewSettings(entities, settings) {
    entities.forEach((entity) => {
        if (entity.label) {
            entity.label.show = settings.volume_labels;
        }
        if (entity.polygon) {
            const color = entity.polygon.material.getValue().color;
            entity.polygon.material = Color.fromAlpha(color, settings.op_opacity);
        }
        if (entity.ellipse) {
            const color = entity.ellipse.material.getValue().color;
            entity.ellipse.material = Color.fromAlpha(color, settings.op_opacity);
        }
    });
}

export function isAnASDEntity(entity, asdEntities) {
    if (asdEntities.has(entity.id) && !entity.id.includes("_")) {
        return true;
    } else {
        return false;
    }
}

export function getASDMessageType(asd) {
    let type = "";
    if (asd.source_id === "1-1") {
        type = "correlator";
    } else if (asd.source_type === "ADS_B") {
        type = "adsb";
    } else if (asd.source_type === "TELEMETRY") {
        type = "TELEMETRY";
    } else {
        type = "radar";
    }
    return type;
}

export function getAlertRingVisibility(asd_entity, show_alerts) {
    if (asd_entity.show === true && show_alerts === true) {
        return true;
    } else return false;
}

export function getActiveDrawMessageFromMethod(method) {
    if (method === "Upload") {
        return "Click and drag the points to edit your volumes";
    } else if (method === "Automatic") {
        return "Select an operation and buffer below";
    } else if (method === "Waypoints") {
        return "Click to add points, right click to finish";
    } else if (method === "Circle") {
        return "Select a point on the map, edit the radius below";
    } else if (method === "Polygon") {
        return "Click to add points, right click to finish";
    } else {
        return "Select a method to create your volume";
    }
}

export function getAlertColorFromId(id, colors) {
    let alertColor = Color.PINK.withAlpha(0.7);
    if (colors) {
        const color = colors.find((color) => {
            return color.id === id;
        });
        if (color) {
            const color_rgb = color.color_rgb;
            alertColor = Color.fromCssColorString(color_rgb);
        }
    }
    return alertColor;
}

export function getAirspaceVisibilityFromType(type, settings) {
    const setting = settings.find(({ airspace_type }) => {
        return airspace_type === type;
    });
    let visible = true;
    if (setting) {
        visible = setting.visible_map;
    }
    return visible;
}

export function getAirspaceHexColorFromType(type, settings) {
    const setting = settings.find(({ airspace_type }) => {
        return airspace_type === type;
    });
    let airspace_color = "#000000";
    if (setting) {
        airspace_color = setting.color;
    }
    return airspace_color;
}

export function getFacilityMapHexColor(facilityMap) {
    return facilityMap.apt1_laanc ? "#90caf9" : "#FF5050";
}

export function GetDegreesArray(instance) {
    if (instance.polygon !== undefined) {
        const cleanedString = instance.polygon.replace(/[()]/g, "");
        const pairs = cleanedString.split(",");
        return pairs.map(parseFloat);
    } else if (instance.latitude !== undefined && instance.longitude !== undefined) {
        const coordinates = [];
        const numPoints = 36;
        const radius = ConvertNauticalMilesToMeters(3);

        for (let i = 0; i < numPoints; i++) {
            const angle = (360 / numPoints) * i;
            const radians = angle * (Math.PI / 180);

            const latOffset = (radius / 111300) * Math.sin(radians);
            const lonOffset = (radius / (111300 * Math.cos(instance.latitude * (Math.PI / 180)))) * Math.cos(radians);

            const lat = instance.latitude + latOffset;
            const lon = instance.longitude + lonOffset;

            coordinates.push(lon);
            coordinates.push(lat);
        }
        coordinates.push(coordinates[0]);
        coordinates.push(coordinates[1]);
        return coordinates;
    } else {
        return [0, 0, 0, 0];
    }
}

export function GetPrimitiveFromData(data, visibility, color) {
    const geometryInstances = [];
    const colorCesium = Color.fromCssColorString(color);

    data.forEach((instance) => {
        const degreesArray = GetDegreesArray(instance);
        const cartesian = Cartesian3.fromDegreesArray(degreesArray);
        const geometryInstance = new GeometryInstance({
            geometry: new SimplePolylineGeometry({
                positions: cartesian,
                arcType: ArcType.NONE
            }),
            attributes: {
                color: ColorGeometryInstanceAttribute.fromColor(colorCesium)
            }
        });
        geometryInstances.push(geometryInstance);
    });

    const primitive = new Primitive({
        show: visibility,
        geometryInstances: geometryInstances,
        appearance: new PolylineColorAppearance({
            translucent: false
        }),
        allowPicking: false,
        vertexCacheOptimize: true
    });
    primitive.color = color;
    return primitive;
}

export function GetFacilityMapPrimitive(facilityMaps, visibility) {
    const geometryInstances = [];
    facilityMaps.forEach((facilityMap) => {
        const degreesArray = GetDegreesArray(facilityMap);
        const cartesian = Cartesian3.fromDegreesArray(degreesArray);
        const color = Color.fromCssColorString(facilityMap.apt1_laanc ? "#90caf9" : "#FF5050");

        const geometryInstance = new GeometryInstance({
            geometry: new SimplePolylineGeometry({
                positions: cartesian,
                arcType: ArcType.NONE
            }),
            attributes: {
                color: ColorGeometryInstanceAttribute.fromColor(color)
            }
        });
        geometryInstances.push(geometryInstance);
    });
    return new Primitive({
        show: visibility,
        geometryInstances: geometryInstances,
        appearance: new PerInstanceColorAppearance({
            translucent: false,
            flat: true
        }),
        allowPicking: false,
        vertexCacheOptimize: true
    });
}

export function GetFacilityMapLabelCollection(facilityMaps) {
    const collection = new LabelCollection();
    const condition = new DistanceDisplayCondition(0.0e6, 0.0015e7);

    facilityMaps.forEach((facilityMap) => {
        collection.add({
            font: "bold 16px sans-serif",
            text: `${facilityMap.ceiling_ft}ft`,
            distanceDisplayCondition: condition,
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE,
            position: Cartesian3.fromDegrees(facilityMap.longitude, facilityMap.latitude),
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.CENTER
        });
    });
    return collection;
}

export function GetStadiumLabelCollection(stadiums) {
    const collection = new LabelCollection();
    const condition = new DistanceDisplayCondition(0.0e6, 0.0015e7);

    stadiums.forEach((stadium) => {
        collection.add({
            font: "bold 16px sans-serif",
            text: stadium.name,
            distanceDisplayCondition: condition,
            outlineWidth: 3,
            fillColor: Color.WHITE,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE,
            position: Cartesian3.fromDegrees(stadium.longitude, stadium.latitude)
        });
    });
    return collection;
}
export function getOperationDescription(operation) {
    const minAltMeters = operation.volumes[0].altitude_min_agl_m;
    const maxAltMeters = operation.volumes[0].altitude_max_agl_m;
    const description = `
        <p>Operation</p>
        <p>State: ${operation.state}</p>
        <p>Priority: ${getOperationPriority(operation.priority)}</p>
        <p>Altitude Min (AGL): ${ConvertMetersToFeet(minAltMeters)}ft</p>
        <p>Altitude Max (AGL): ${ConvertMetersToFeet(maxAltMeters)}ft</p>
        <p>Time Start: ${ConvertISOToDate(operation.time_start)}</p>
        <p>Time End: ${ConvertISOToDate(operation.time_end)}</p>
    `;
    return description;
}

export function getAirspaceDescription(airspace) {
    const description = `
        <p>Airspace</p> 
        <p>Airspace Type: ${airspace.local_type}</p> 
        <p>Date Updated: ${ConvertISOToDate(airspace.date_updated)}</p>
    `;
    return description;
}

export function getConstraintDescription(constraint) {
    const minAltMeters = constraint.volumes[0].altitude_min_agl_m;
    const maxAltMeters = constraint.volumes[0].altitude_max_agl_m;
    const description = `
        <p>Constraint</p>
        <p>State: ${constraint.state}</p>
        <p>Altitude Min (AGL): ${ConvertMetersToFeet(minAltMeters)}ft</p>
        <p>Altitude Max (AGL): ${ConvertMetersToFeet(maxAltMeters)}ft</p>
        <p>Time Start: ${ConvertISOToDate(constraint.time_start)}</p>
        <p>Time End: ${ConvertISOToDate(constraint.time_end)}</p>
    `;
    return description;
}

export function getAlertVolumeDescription(alertVolume) {
    const minAltMeters = alertVolume.altitude_min_agl_m;
    const maxAltMeters = alertVolume.altitude_max_agl_m;
    const description = `
        <p>Alert Volume</p>
        <p>Altitude Min (AGL): ${ConvertMetersToFeet(minAltMeters)}ft</p>
        <p>Altitude Max (AGL): ${ConvertMetersToFeet(maxAltMeters)}ft</p>
        <p>Time Start: ${ConvertISOToDate(alertVolume.time_start)}</p>
        <p>Time End: ${ConvertISOToDate(alertVolume.time_end)}</p>
        <p>Date Created: ${ConvertISOToDate(alertVolume.date_created)}</p>
    `;
    return description;
}

export function getEntitiesFromOperationMessage(message, settings) {
    const entityCollection = new EntityCollection();
    message.forEach((operation) => {
        const description = getOperationDescription(operation);
        const color = GetColorFromState(operation.state, settings.op_state_settings);
        const opacity = settings.op_opacity;
        operation.volumes.forEach((volume, index) => {
            const entity = {
                name: operation.name,
                description: description,
                polygon: new PolygonGraphics({
                    extrudedHeightReference: HeightReference.RELATIVE_TO_GROUND,
                    heightReference: HeightReference.RELATIVE_TO_GROUND,
                    extrudedHeight: volume.altitude_max_agl_m,
                    height: volume.altitude_min_agl_m,
                    material: Color.fromCssColorString(color).withAlpha(opacity),
                    arcType: ArcType.GEODESIC,
                    outline: true,
                    outlineColor: Color.WHITE
                })
            };
            if (index === 0) {
                entity.label = {
                    font: "14pt monospace",
                    text: operation.name,
                    verticalOrigin: VerticalOrigin.CENTER,
                    style: LabelStyle.FILL_AND_OUTLINE,
                    pixelOffset: new Cartesian2(0, -9),
                    outlineWidth: 2
                };
                entity.point = {
                    pixelSize: 1,
                    color: Color.WHITE
                };
            }
            if (Object.prototype.hasOwnProperty.call(volume, "polygon")) {
                const center = ConvertVerticesToAveragePosition(volume.polygon);
                const position = ConvertVerticesToPosition(volume.polygon);

                entity.position = Cartesian3.fromDegrees(center.lng, center.lat);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(position);
            } else if (Object.prototype.hasOwnProperty.call(volume, "circle")) {
                const latitude = volume.circle.center.lat;
                const longitude = volume.circle.center.lng;
                const radius = volume.circle.radius_m;
                const degreesArray = GetDegreesArrayFromLatLongAndRadius(latitude, longitude, radius);

                entity.position = Cartesian3.fromDegrees(longitude, latitude);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(degreesArray);
            }
            entityCollection.add(entity);
        });
    });
    return entityCollection;
}

export function getEntitiesFromAirspaces(airspaces, settings) {
    const entityCollection = new EntityCollection();
    airspaces.forEach((airspace) => {
        const color = getAirspaceHexColorFromType(airspace.local_type, settings.airspace_settings);
        const positions = GetDegreesArrayFromPolygon(airspace.polygon);
        const description = getAirspaceDescription(airspace);
        const airspaceEntity = {
            name: airspace.name,
            description: description,
            airspace_type: airspace.local_type,
            polygon: {
                hierarchy: Cartesian3.fromDegreesArray(positions),
                height: 0,
                material: Color.TRANSPARENT,
                outline: true,
                outlineColor: Color.fromCssColorString(color)
            }
        };
        entityCollection.add(airspaceEntity);
    });
    return entityCollection;
}

export function getEntitiesFromConstraintMessage(message, settings) {
    const entityCollection = new EntityCollection();
    message.forEach((constraint) => {
        const description = getConstraintDescription(constraint);
        const color = GetColorFromState(constraint.state, settings.op_state_settings);
        const opacity = settings.op_opacity;
        constraint.volumes.forEach((volume, index) => {
            const entity = {
                name: constraint.name,
                description: description,
                polygon: new PolygonGraphics({
                    extrudedHeightReference: HeightReference.RELATIVE_TO_GROUND,
                    heightReference: HeightReference.RELATIVE_TO_GROUND,
                    extrudedHeight: volume.altitude_max_agl_m,
                    height: volume.altitude_min_agl_m,
                    material: Color.fromCssColorString(color).withAlpha(opacity),
                    arcType: ArcType.GEODESIC,
                    outline: true,
                    outlineColor: Color.WHITE
                })
            };
            if (index === 0) {
                entity.label = {
                    font: "14pt monospace",
                    text: constraint.name,
                    verticalOrigin: VerticalOrigin.CENTER,
                    style: LabelStyle.FILL_AND_OUTLINE,
                    pixelOffset: new Cartesian2(0, -9),
                    outlineWidth: 2
                };
                entity.point = {
                    pixelSize: 1,
                    color: Color.WHITE
                };
            }
            if (Object.prototype.hasOwnProperty.call(volume, "polygon")) {
                const center = ConvertVerticesToAveragePosition(volume.polygon);
                const position = ConvertVerticesToPosition(volume.polygon);

                entity.position = Cartesian3.fromDegrees(center.lng, center.lat);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(position);
            } else if (Object.prototype.hasOwnProperty.call(volume, "circle")) {
                const latitude = volume.circle.center.lat;
                const longitude = volume.circle.center.lng;
                const radius = volume.circle.radius_m;
                const degreesArray = GetDegreesArrayFromLatLongAndRadius(latitude, longitude, radius);

                entity.position = Cartesian3.fromDegrees(longitude, latitude);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(degreesArray);
            }
            entityCollection.add(entity);
        });
    });
    return entityCollection;
}

export function getEntitiesFromAlertVolumeMessage(message, settings) {
    const entityCollection = new EntityCollection();
    message.forEach((alertVolume) => {
        const description = getAlertVolumeDescription(alertVolume);
        const color = alertVolume.color_rgb;
        const opacity = settings.op_opacity;
        alertVolume.polygons.forEach((polygon, index) => {
            const entity = {
                name: alertVolume.name,
                description: description,
                polygon: new PolygonGraphics({
                    extrudedHeightReference: HeightReference.RELATIVE_TO_GROUND,
                    heightReference: HeightReference.RELATIVE_TO_GROUND,
                    extrudedHeight: alertVolume.altitude_max_agl_m,
                    height: alertVolume.altitude_min_agl_m,
                    material: Color.fromCssColorString(color).withAlpha(opacity),
                    arcType: ArcType.GEODESIC,
                    outline: true,
                    outlineColor: Color.WHITE
                })
            };
            if (index === 0) {
                entity.label = {
                    font: "14pt monospace",
                    text: alertVolume.name,
                    verticalOrigin: VerticalOrigin.CENTER,
                    style: LabelStyle.FILL_AND_OUTLINE,
                    pixelOffset: new Cartesian2(0, -9),
                    outlineWidth: 2
                };
                entity.point = {
                    pixelSize: 1,
                    color: Color.WHITE
                };
            }
            if (polygon.type === "polygon") {
                const entityVerticies = polygon.vertices;
                const center = ConvertVerticesToAveragePosition(entityVerticies);
                const position = ConvertVerticesToPosition(entityVerticies);

                entity.position = Cartesian3.fromDegrees(center.lng, center.lat);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(position);
            } else if (polygon.type === "circle") {
                const latitude = polygon.lat;
                const longitude = polygon.lng;
                const radiusMeters = polygon.radius;
                const degreesArray = GetDegreesArrayFromLatLongAndRadius(latitude, longitude, radiusMeters);

                entity.position = Cartesian3.fromDegrees(longitude, latitude);
                entity.polygon.hierarchy = Cartesian3.fromDegreesArray(degreesArray);
            }
            entityCollection.add(entity);
        });
    });
    return entityCollection;
}
export function handleCreateProviderViewModels(data) {
    const newProviderViewModels = createDefaultImageryProviderViewModels();
    newProviderViewModels.push(
        new ProviderViewModel({
            name: "Bing Maps Dark Mode",
            category: "Cesium ion",
            iconUrl: buildModuleUrl("Widgets/Images/ImageryProviders/bingAerialLabels.png"),
            tooltip: "Bing Maps Dark Mode",
            creationFunction: async () => {
                const provider = await BingMapsImageryProvider.fromUrl("https://dev.virtualearth.net", {
                    key: "Ar-ScCJlElwxMvlzyVeEz8DDKKU_149mcLrM3aotxGVmRmLZ2I9XcvytKqcZUV_H",
                    mapStyle: BingMapsStyle.CANVAS_DARK
                });
                return provider;
            }
        })
    );
    const filteredProviderViewModels = newProviderViewModels.filter((view_model) => {
        return view_model.name.includes("Stadia") === false;
    });
    try {
        const tiles = JSON.parse(data);
        tiles.forEach(({ name, url }, i) => {
            const newModel = new ProviderViewModel({
                name: name,
                tooltip: name,
                category: "Cesium ion",
                iconUrl: buildModuleUrl("Widgets/Images/ImageryProviders/bingAerialLabels.png"),
                creationFunction: () => {
                    return new UrlTemplateImageryProvider({
                        url: `${url}/{z}/{x}/{y}.png`,
                        key: i
                    });
                }
            });
            filteredProviderViewModels.push(newModel);
        });
        return filteredProviderViewModels;
    } catch (err) {
        return filteredProviderViewModels;
    }
}

export function getCheckedFromSubLayers(subLayers) {
    return subLayers.every((subLayer) => {
        return subLayer.checked === true;
    });
}
export function getIndeterminateFromSubLayers(subLayers) {
    if (subLayers.length > 0) {
        return subLayers.some(({ checked }) => {
            return checked !== subLayers[0].checked;
        });
    } else {
        return undefined;
    }
}
